<template>
  <div id="lottery-terms" class="default-page">
    <!-- Texto de Información. -->
    <div class="container-fluid centered-layout">
      <div class="row justify-content-center">
        <div class="mx-2 mx-lg-0 mt-5 p-5 px-lg-0 w-100 col-lg-8">
          <h1 class="title text-black mt-5 text-center mb-0">Condiciones Generales del Sorteo</h1>
          <h2 class="title text-black text-center pt-3">"¡Parque Warner espera a alguien de tu club!"</h2>
          <h4>
            Organizador del Sorteo
          </h4>
          <p>
            El sorteo es organizado por Cluber Siguetuliga SL (en adelante, el “Organizador”) y está dirigido a los usuarios de los clubes que hayan adquirido una rifa válida durante el período de vigencia del sorteo en cualquiera de los clubes participantes.
          </p>
          <h4>
            Período de Vigencia
          </h4>
          <p>
            La promoción se realizará desde el día <strong>13/01/24</strong> hasta el día <strong>31/03/25</strong>, ambos incluidos. Solo las rifas adquiridas dentro de este período participarán en el sorteo.
          </p>
          <h4>
            Elegibilidad
          </h4>
          <p>
            Pueden participar en el sorteo todas las personas físicas mayores de 18 años que residan en España.
          </p>
          <h4>
            Mecánica de Participación
          </h4>
          <p>Existen dos formas de participación en el sorteo, las cuales no son excluyentes entre sí:</p>
          <ol>
            <li>
              <p><strong>Registro de nuevos usuarios:</strong> Cada nuevo registro de usuario (tutor/deportista) en la plataforma Cluber entre los días 15/1/25 y 31/3/25 otorga una participación al sorteo. El registro implica cumplimentar datos personales y aportar un método de pago válido (ya sea tarjeta o domiciliación bancaria, según corresponda).</p>
            </li>
            <li>
              <p><strong>Donativos a clubes:</strong> cada donativo de 3€ a un club en la campaña creada para este efecto otorga una participación al sorteo. En una misma operación en la campaña un usuario puede hacer varios donativos. Tendrá tantas participaciones en el sorteo como donativos haya en su compra. Un usuario puede adquirir participaciones al sorteo a través de varios clubes. De este modo, cada registro y cada donativo realizado incrementa las oportunidades de ganar, permitiendo que los participantes se beneficien tanto por su inscripción en la plataforma como por su apoyo a los clubes.</p>
            </li>
          </ol>
          <h4>
            Premio
          </h4>
          <p class="mb-1">
            El premio consiste en una experiencia en el Parque Warner para dos adultos y dos niños, que incluye entradas para acceder a Parque Warner durante 2 (dos) días consecutivos, así como 1 (una) noche de alojamiento en el Hotel Holiday Inn Express Madrid Alcorcón. Los dos días de disfrute podrán ser seleccionados por el ganador entre el 15 de abril de 2025 y el 31 de mayo de 2025.
          </p>
          <small><p class="mb-1">Nota: Cualquier modificación de las condiciones del viaje tales como el lugar de origen o las fechas debe ser negociada directamente entre el ganador y la agencia organizadora del viaje.</p></small>
          <small><p>Los gastos no especificados en la descripción del premio (como gastos personales, tasas locales, seguros de viaje…) correrán a cargo del ganador.</p></small>
          <h4>
            Fecha y Modo de Elección del Ganador
          </h4>
          <p>
            El sorteo se realizará de manera aleatoria mediante la aplicación <a href="https://app-sorteos.com/es">AppSorteos</a> y con transmisión en vivo a través de nuestra cuenta de Instagram <a href="https://www.instagram.com/cluber_app">@cluberapp</a> en el plazo de diez días tras la finalización de la campaña (31/3/25). Se seleccionará un (1) ganador y tres (3) suplentes en caso de que el ganador principal no pueda o no cumpla con los requisitos para reclamar el premio.
          </p>
          <h4>
            Notificación del Ganador
          </h4>
          <p>
            El Organizador notificará al ganador a través de correo electrónico en un plazo de 3 días tras la realización del sorteo, así como al club al que pertenece. Si el ganador no responde en un período de 5 días, se contactará al primer suplente, y así sucesivamente hasta que el premio sea adjudicado.
          </p>
          <h4>Reclamo del Premio</h4>
          <p>
            El ganador deberá presentar su comprobante para validar su participación y reclamar el premio ante el club.
          </p>
          <p>
            El premio es personal e intransferible, y no puede ser canjeado por su valor en efectivo ni transferido a terceros.
          </p>
          <p>
            El Organizador patrocinará al club al que pertenece el ganador los costes del premio así como las retenciones fiscales derivados del mismo. El club será quien haga entrega del premio a su usuario.    
          </p>
          <p>
            El ganador deberá coordinar los detalles del viaje o posibles modificaciones con la agencia organizadora del viaje.
          </p>
          <h4>Responsabilidades y Limitaciones</h4>
          <p>
            El Organizador no se hace responsable de cualquier imprevisto, retraso o situación adversa que pueda ocurrir durante el viaje, ni de gastos adicionales derivados de causas fuera de su control.
          </p>
          <p>
            El Organizador se reserva el derecho de descalificar a cualquier participante que incumpla las condiciones del sorteo o utilice métodos fraudulentos para participar.
          </p>
          <h4>
            Protección de Datos
          </h4>
          <p>
            Los datos personales de los participantes serán tratados de conformidad con la legislación de protección de datos vigente. La información recopilada solo será utilizada por el Organizador para gestionar el sorteo.
          </p>
          <h4>Aceptación de las Condiciones</h4>
          <p>
            La participación en este sorteo implica la aceptación íntegra de estas condiciones. Cualquier situación no contemplada en estas bases será resuelta por el Organizador de acuerdo con la normativa vigente.
          </p>
          <h4>
            Modificación o Cancelación del Sorteo
          </h4>
          <p>
            El Organizador se reserva el derecho de modificar o cancelar el sorteo en cualquier momento, sin previo aviso, siempre y cuando existan razones justificadas.
          </p>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

export default {
  name: "psd2-info",
  bodyClass: "default-page",
  data() {
    return {
      currentTimeout: undefined,
    }
  },
  mounted(){
    this.removeZohoWidget()
  },
  beforeDestroy(){
    if (this.currentTimeout) clearTimeout(this.currentTimeout)
    $zoho.salesiq.floatbutton.visible("show")
  },
  methods: {
    removeZohoWidget() {
      if (typeof $zoho === 'undefined') {
        this.currentTimeout = setTimeout(this.removeZohoWidget, 100)
        return
      }
      $zoho.salesiq.floatbutton.visible("hide")
    }
  }
};
</script>
<style scoped>
.page-header--psd2 {
  height: auto !important;
}

li {
  list-style: circle !important;
}

p {
  font-size: 1.4em;
  font-weight: 400;
  text-align: justify;
}
h4 {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

ol li::marker {
  font-size: 1rem;
  font-weight: bold;
}
</style>